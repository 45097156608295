<template>
  <div class="check-in container">
    <div id="checkin-list" class="my-3">
      <h3 class="mb-3">{{ $t('label.checkInList') }} </h3>
      <template v-if="isClosed">
        <div class="alert alert-info">
          <font-awesome-icon icon="exclamation-triangle" />
          {{ $t('message.closed') }}
        </div>
      </template>
      <template v-else>
        <form @submit.prevent="checkInNewPlayer(form.entryName)">
          <div class="input-group mb-3">
            <input type="text" class="form-control" name="newCheckIn"
              placeholder="Player name..." v-model="form.entryName">
            <div class="input-group-append">
              <button class="btn btn-primary" type="submit" :disabled="isBusy">{{ $t('label.checkIn') }}</button>
            </div>
          </div>
        </form>
        <form @submit.prevent="readTwipla(form.eventId)">
          <div class="input-group mb-3">
            <input type="text" class="form-control" name="twiplaCheckIn"
              placeholder="twipla event id" v-model="form.eventId">
            <div class="input-group-append">
              <button class="btn btn-primary" type="submit" :disabled="isBusy">{{ $t('label.checkIn') }}</button>
            </div>
          </div>
        </form>
      </template>
      <table class="table">
        <colgroup>
          <col class="col-checkin-num">
          <col>
          <col class="col-action">
        </colgroup>
        <tr>
          <th>{{ $t('label.checkInNo') }}</th>
          <th>{{ $t('label.name') }}</th>
          <th>{{ $t('label.action') }}</th>
        </tr>
        <tr v-for="p in players" :key="p.seq" :class="{ dropped: p.isDropped }">
          <td>{{ p.seq }}</td>
          <td>
            <template v-if="p.seq === renamingSeq">
              <form class="form-inline" @submit.prevent="renamePlayer(form.renameField)">
                <input class="form-control mr-2" type="text" v-model="form.renameField" />
                <button class="btn btn-sm btn-light mr-2" type="submit">&#x2713;</button>
                <button class="btn btn-sm btn-light mr-2" type="button" @click="abortRename">&times;</button>
              </form>
            </template>
            <template v-else>
              {{ p.name }}
            </template>
          </td>
          <td class="dropdown">
            <button class="btn btn-light btn-sm" data-toggle="dropdown"><font-awesome-icon icon="ellipsis-h" /></button>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" v-if="!isClosed" @click="showRenameForm(p.seq)">{{ $t('label.rename') }}</a>
              <a class="dropdown-item" v-if="!isClosed" @click="deletePlayer(p.seq)">{{ $t('label.delete') }}</a>
              <a class="dropdown-item" v-if="isClosed" @click="dropPlayer(p.seq)">{{ $t('label.drop') }}</a>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { TYPE } from "vue-toastification"
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'CheckIn',
  components: {
  },
  data() {
    return {
      form: {
        entryName: "",
        renameField: "",
      },
      renamingSeq: null,
      isBusy: false,
    }
  },
  computed: {
    ...mapState('event', {
      players: state => state.player.list,
      isClosed: state => state.round.count > 0
    })
  },
  methods: {
    checkInNewPlayer(name) {
      if(name) {
        this.$store.commit('event/addPlayer', { name })
        this.form.entryName = ""
      }
    },
    showRenameForm(seq) {
      this.renamingSeq = seq
      this.form.renameField = this.players.find(p => p.seq == seq).name
    },
    abortRename() {
      this.renamingSeq = null
    },
    renamePlayer(name) {
      if(name) {
        this.$store.commit('event/renamePlayer', {
          seq: this.renamingSeq,
          name: name
        })
        this.renamingSeq = null
      }
    },
    deletePlayer(seq) {
      const name = this.players.find(p => p.seq === seq).name
      this.$store.commit('event/deletePlayer', seq)
      this.$toast.success(`Deleted player#${seq} ${name}.`)

      if(this.$store.state.config.isCheckinReassign) {
        this.$store.commit('event/reassignPlayers')
      }
    },
    dropPlayer(seq) {
      const name = this.players.find(p => p.seq === seq).name
      this.$store.commit('event/dropPlayer', seq)
      this.$toast.success(`Dropped player#${seq} ${name}.`)
    },
    readTwipla: function(eventId){
      this.isBusy = true
      const busyToast = this.$toast(this.$t('message.twipla.loading'), { timeout: false }, true)
      axios.get('https://cksa7u26z0.execute-api.ap-northeast-1.amazonaws.com/api/twipla_reader', {params:{event_id:eventId}})
          .then(response => {
            let elems = response.data.reverse() // Join date DESC -> ASC
            this.$store.commit('event/addPlayers', { list: elems.map(e => e.trim()) })
            
            this.$toast.update(busyToast, {
              content: this.$t('message.twipla.loaded'),
              options: {
                type: TYPE.SUCCESS,
                timeout: 3000,
              }
            })
            this.form.eventId = ""
          })
          .catch(error => {
            console.log('Error', error.message)
            this.$toast.update(busyToast, {
              content: this.$t('message.twipla.error'),
              options: {
                type: TYPE.ERROR,
                timeout: 3000,
              }
            })
          })
          .finally(() => {
            this.isBusy = false
          })
    },
  }
}
</script>
<style scoped>
.col-checkin-num {
  width: 4em;
}
.col-action {
  width: 4em;
}
.table td {
  vertical-align: baseline;
}
.table td:nth-child(1),
.table td:nth-child(3) {
  text-align: center;
}
.dropped {
  color: silver;
}
.dropped td:nth-child(2)::before {
  content: '(Dropped) ';
}
</style>
<i18n>
{
  "en": {
    "label": {
      "checkInList": "Check In List",
      "checkIn": "Check In",
      "checkInNo": "No.",
      "name": "Name",
      "action": "Action",
      "rename": "Rename",
      "delete": "Delete",
      "drop": "Drop"
    },
    "message": {
      "closed": "New players are no longer appended after a game started.",
      "twipla": {
        "loading": "Loading event member list...",
        "loaded": "Loaded member list!",
        "error": "Loading error!"
      }
    }
  },
  "ja": {
    "label": {
      "checkInList": "チェックインリスト",
      "checkIn": "チェックイン",
      "checkInNo": "番号",
      "name": "名前",
      "action": "操作",
      "rename": "名前の変更",
      "delete": "削除",
      "drop": "ドロップ"
    },
    "message": {
      "closed": "試合開始後に新たなプレイヤーは追加できません。",
      "twipla": {
        "loading": "イベント参加者一覧を読み込んでいます...",
        "loaded": "イベント参加者一覧を読み込みました。",
        "error": "読み込み時にエラーが発生しました。"
      }
    }
  }
}
</i18n>
